<template>
  <ams-table
    :ams_api_path="ams_api_path"
    :ams_type="ams_type"
    :ams_fields="ams_fields"
    :describe_belongs_to="describe_belongs_to"
    :filtering="filtering"
    :refresh="refresh"
    :refreshed="doRefresh"
    :per_page_setting="per_page_setting"
    :reroute_name="reroute_name"
    :ams_template="ams_template"
  >
  </ams-table>
</template>

<script>
import AmsTable from './AmsTable.vue'

export default {
  components: {
    AmsTable
  },
  props: {
    filtering: {
      Type: Object
    },
    refresh: {
      Type: Boolean
    },
    per_page_setting: {
      Type: Number
    },
    ams_template: {
      Type: Object
    }
  },
  data() {
    return {
      ams_type: 'product-items',
      ams_api_path: 'api/admin/v1',
      reroute_name: 'ProductItemCreateScreen',
      ams_fields: [
       { 
          key: 'attributes.serial-number',
          label: 'Serial #',
          sortable: true,
          filterable: true,
          class: 'text-left',
          sortDirection: 'asc'
        },
       { 
          key: 'attributes.at-status',
          label: 'Collection Status',
          sortable: true,
          filterable: true,
          class: 'text-left',
          sortDirection: 'asc' 
        },
       { 
          key: 'attributes.purchased-price-cents',
          label: 'Cost',
          sortable: true,
          filterable: true,
          class: 'text-right',
          sortDirection: 'asc',
          formatter: value => {
              return '$' + (value / 100).toFixed(2)
          }
        },
        { 
          key: 'attributes.estimated-min-price-cents',
          label: 'Min Estimated Price',
          sortable: true,
          filterable: false,
          class: 'text-right',
          sortDirection: 'asc',
          formatter: value => {
              return '$' + (value/ 100).toFixed(2)
          }        
        },
        { 
          key: 'attributes.estimated-max-price-cents',
          label: 'Max Estimated Price',
          sortable: true,
          filterable: false,
          class: 'text-right',
          sortDirection: 'asc',
          formatter: value => {
              return '$' + (value / 100).toFixed(2)
          }
        },
        { 
          key: 'attributes.estimated-quality',
          label: 'Quality',
          sortable: true,
          filterable: false,
          class: 'text-right',
          sortDirection: 'asc',
          formatter: value => {
              return (value / 100).toFixed(2) + '%'
          } 
        },
       { 
          key: 'attributes.asking-price-cents',
          label: 'Asking Price',
          sortable: true,
          filterable: false,
          class: 'text-right',
          sortDirection: 'asc',
          formatter: value => {
              return '$' + (value / 100).toFixed(2)
          }
        },
      ],
      describe_belongs_to: [
        {
          prepend: '',
          type: 'brands',
          description_attr: 'name',
          append: ': '
        },
        {
          prepend: '',
          type: 'product-models',
          description_attr: 'name',
          append: ' '
        },
        {
          prepend: '#',
          type: 'product-listings',
          description_attr: 'edition',
          append: ''
        },
        {
          prepend: ' of ',
          type: 'collections',
          description_attr: 'name',
          append: ''
        }
      ]
    }
  },
  methods: {
    doRefresh() {
      this.emit('refreshed', true)
    }
  }
}
</script>